import 'rc-slider/assets/index.css'

import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import ChariotConnect from 'react-chariot-connect'
import { Button } from '~/components/button'
import { Box, Grid } from '~/components/layout'
import { SelectField, SelectProps } from '~/components/select'
import { Text } from '~/components/typography'
import { routes } from '~/config/routes'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import {
  DonationRecurrenceInterval,
  DonationTier,
  PageStatus,
} from '~/types/graphql'
import { FocusError } from '~/utils/focus-error'
import { isEmpty } from '~/utils/is-empty'

import LockIcon from './assets/lock-icon.svg'
import { useForm } from './hooks/use-form'
import {
  AmountButton,
  AmountButtonsWrapper,
  AmountInputField,
  ButtonBody,
  PaymentRecurrenceSelectMobileWrapper,
  PaymentRecurrenceSelectWrapper,
  SelectDonationSectionWrapper,
  SubmitBtnWrapper,
} from './style'

type Props = {
  page: PageBySlugQuery['pageBySlug']
  onSubmit: () => void
  isUsingChariot?: boolean
}

const options: SelectProps<DonationRecurrenceInterval>['options'] = [
  {
    label: 'One-Time',
    value: DonationRecurrenceInterval.None,
  },
  {
    label: 'Monthly',
    value: DonationRecurrenceInterval.Monthly,
  },
  {
    label: 'Quarterly',
    value: DonationRecurrenceInterval.Quarterly,
  },
  {
    label: 'Yearly',
    value: DonationRecurrenceInterval.Yearly,
  },
]

export type ApplyOptions = 'default'

export const SelectDonation = (props: Props) => {
  const { query } = useRouter()
  const { onSubmit, page, isUsingChariot = false } = props
  const { slug, status, allowCustomAmount, defaultDonationRecurrenceInterval } =
    page
  const closed = status === PageStatus.Closed

  const pageTiers: Array<DonationTier> = new Array()
  const router = useRouter()

  page.donationTiers.slice(-4).map(tier => {
    return pageTiers.push({
      id: `$${(tier.amount / 100).toLocaleString('en')}`,
      amount: tier.amount,
      recurrenceInterval: DonationRecurrenceInterval.None,
      description: '',
      isDefault: tier.isDefault,
    })
  })

  if (allowCustomAmount && !isEmpty(pageTiers)) {
    pageTiers.push({
      id: 'Custom',
      amount: 0,
      recurrenceInterval: DonationRecurrenceInterval.None,
      description: '',
      isDefault: false,
    })
  }

  const onDonationRequest = (donationAmount: number) => {
    const donationRequestData = {
      amount: donationAmount > 0 ? donationAmount : undefined,
      metadata: {
        fundraiserPageName: page.title,
      },
    }

    return donationRequestData
  }
  const onSuccess = (e: any) => {
    const detail = e.detail
    const grantIntent = e.detail.grantIntent
    const chariotSessionData = {
      workflowSessionId: detail.workflowSessionId,
      userFriendlyId: grantIntent.userFriendlyId,
      fundId: grantIntent.fundId,
      amount: grantIntent.amount,
    }

    sessionStorage.setItem(
      'chariotSessionData',
      JSON.stringify(chariotSessionData)
    )
    router.push(
      `${routes.donationPage(slug!).as}?chariot=${chariotSessionData.workflowSessionId
      }`
    )
  }
  const onExit = () => null

  const { schema } = useForm()

  const getData = () => {
    let initialData = {
      amount: 0,
      paymentFrequency: defaultDonationRecurrenceInterval,
    }

    if (typeof window !== 'undefined' && sessionStorage?.formData) {
      const inputData = JSON.parse(sessionStorage.formData)
      initialData = {
        ...initialData,
        ...inputData,
        paymentFrequency: inputData.paymentFrequency,
      }

    } else {
      if (query.tier && query.tier !== 'custom') {
        const tier = page.donationTiers.find(tier => tier.id === query.tier)
        initialData = {
          amount: tier?.amount!,
          paymentFrequency: page.donationTiers.find(
            tier => tier.id === query.tier
          )?.recurrenceInterval!,
        }
      } else {
        initialData = {
          ...initialData,
          paymentFrequency: defaultDonationRecurrenceInterval,
        }
      }
    }
    return initialData
  }

  return (
    <Formik
      initialValues={getData()}
      validationSchema={schema}
      onSubmit={values => {
        sessionStorage.setItem('formData', JSON.stringify(values))
        onSubmit()
      }}
    >
      {({ values, setFieldValue }) => {
        const handleAmountChange = (value: number) => {
          setFieldValue('amount', value)
        }

        return (
          <>
            <FocusError />
            <Form>
              <SelectDonationSectionWrapper>
                {isEmpty(pageTiers) ? (
                  <Text bold marginBottom="0.25rem">
                    Donation Amount
                  </Text>
                ) : (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                      marginBottom={'1rem'}
                    >
                      <AmountButtonsWrapper>
                        {pageTiers.map((tier, index) => (
                          <AmountButton
                            isSelected={
                              pageTiers.some(i => i.amount == values.amount)
                                ? values.amount === tier.amount
                                : 0 == tier.amount
                            }
                            onClick={() => {
                              setFieldValue('amount', tier.amount)
                            }}
                            key={index}
                          >
                            {tier.id}
                          </AmountButton>
                        ))}
                      </AmountButtonsWrapper>
                      <PaymentRecurrenceSelectWrapper>
                        <SelectField
                          name="paymentFrequency"
                          options={options}
                        />
                      </PaymentRecurrenceSelectWrapper>
                    </Box>
                    <AmountInputField
                      name="amount"
                      gridColumn={[null, '1 / 4']}
                      value={values.amount}
                      onChange={handleAmountChange}
                      autoFocus
                      disabled={!allowCustomAmount}
                    />
                  </>
                )}

                {isEmpty(pageTiers) ? (
                  <Grid
                    gridTemplateColumns={['1fr', '1fr 1fr 1fr 1fr']}
                    gridColumnGap={16}
                  >
                    <AmountInputField
                      name="amount"
                      gridColumn={[null, '1 / 4']}
                      value={values.amount}
                      onChange={handleAmountChange}
                      autoFocus
                      disabled={!allowCustomAmount}
                    />
                    <SelectField name="paymentFrequency" options={options} />
                  </Grid>
                ) : (
                  <PaymentRecurrenceSelectMobileWrapper>
                    <SelectField name="paymentFrequency" options={options} />
                  </PaymentRecurrenceSelectMobileWrapper>
                )}
              </SelectDonationSectionWrapper>
              <SubmitBtnWrapper>
                <Button
                  type="submit"
                  width="100%"
                  disabled={values.amount == 0 || closed}
                >
                  <ButtonBody>
                    <LockIcon />
                    {closed ? 'Campaign Has Ended' : 'Donate with Credit Card'}
                  </ButtonBody>
                </Button>
                {isUsingChariot && page.pageFeatures.chariotButton ? (
                  <ChariotConnect
                    cid={page.chariotConnectId!}
                    onDonationRequest={() => onDonationRequest(values.amount)}
                    theme="KindestTheme"
                    disabled={closed}
                    onSuccess={onSuccess}
                    onExit={onExit}
                  />
                ) : (
                  <></>
                )}
              </SubmitBtnWrapper>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}
